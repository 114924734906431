<template>
  <div>
    <b-modal
      modal
      v-model="sendToStandBy"
      title="SEND TO STAND BY"
      size="xmd"
      modal-class="modal-primary"
      title-tag="h3"
      :no-close-on-backdrop="true"
      @hidden="closeModal"
    >
      <ValidationObserver ref="form">
        <b-row cols="2">
          <b-col>
            <b-input-group prepend="CLIENT">
              <div
                class="form-control h-auto bg-transparent border-secondary sms-container"
              >
                {{ callRoundUser.client_name }}
              </div>
            </b-input-group>
          </b-col>
          <b-col>
            <ValidationProvider v-slot="{ errors }" rules="required">
              <b-input-group prepend="DATE STAND BY">
                <b-form-datepicker
                  v-model="dateStandBy"
                  :state="errors[0] ? false : null"
                ></b-form-datepicker>
              </b-input-group>
            </ValidationProvider>
          </b-col>
        </b-row>
      </ValidationObserver>
      <template #modal-footer>
        <b-button variant="success" @click="sendToStanBy">
          <span>SAVE</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import CallRoundsService from "@/views/commons/call-rounds/service/call-rounds.service";

export default {
  props: {
    callRoundUser: {
      type: Object,
    },
  },
  data() {
    return {
      dateStandBy: null,
      sendToStandBy: false,
    };
  },
  created() {
    this.sendToStandBy = true;
  },
  methods: {
    async sendToStanBy() {
      const success = await this.$refs.form.validate();
      if (!success) {
      } else {
        try {
          this.addPreloader();
          const params = {
            datestandby: this.dateStandBy,
            idcall: this.callRoundUser.id,
          };
          const data = await CallRoundsService.sendToStandBy(params);
          if (data.status == 200) {
            this.removePreloader();
            this.showSuccessSwal();
            this.closeModal();
          }
        } catch (e) {
          console.log(e);
          this.removePreloader();
        }
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style></style>
