export default [
    {
        key: "created_at",
        label: "Date",
    },
    {
        key: "user_name",
        label: "User",
    },
    {
        key: "type",
        label: "Action",
    },
    {
        key: "comments",
        label: "Comment",
    },
]