<template>
  <div class="border-top-info border-3 border-table-radius px-0">
    <!-- Table -->
    <!-- <filter-slot
        :filter="filter"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :no-visible-principal-filter="true"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        :send-multiple-sms="false"
        @reload="$refs['refCallRounds'].refresh()"
      > -->
    <filter-slot
      :filter="filter"
      :totalRows="totalRows"
      :paginate="paginate"
      :startPage="startPage"
      :toPage="toPage"
      @onChangeCurrentPage="onChangeCurrentPage"
      :filter-principal="filterPrincipal"
      @reload="$refs['refCallRounds'].refresh()"
    >
      <!-- <b-table
          slot="table"
          no-provider-filtering
          ref="refCallRounds"
          :items="myProvider"
          :fields="visibleFields"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          sticky-header="50vh"
          :busy="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :filter="searchInput"
        > -->
      <template #table>
        <b-table
          slot="table"
          small
          table-class="text-nowrap"
          :items="myProvider"
          :fields="visibleFields"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          ref="refCallRounds"
          :busy="isBusy"
          sticky-header="60vh"
          show-empty
          responsive="sm"
          @sort-changed="changeStatusOrder"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1"></b-spinner>
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #cell(client_name)="data">
            <router-link
              :class="[textLink]"
              :to="`/${nameModule}/clients/account/${data.item.account_id}`"
              target="_blank"
            >
              {{ data.item.client_name }}
            </router-link>
          </template>

          <template #cell(task)="data">
            <b-row cols="2" class="text-primary">
              <b-col class="text-left">
                <span class="mr-3">{{ data.item.task }}</span>
              </b-col>
              <b-col class="text-right" v-if="tr == 1">
                <tabler-icon
                  icon="InfoCircleIcon"
                  size="20"
                  class="cursor-pointer"
                  @click="openModalTaskCallRounds(data.item)"
                />
              </b-col>
            </b-row>
          </template>

          <template #cell(status)="data">
            <div>
              <b-badge v-if="data.item.status == 1" variant="warning"
                >PENDING</b-badge
              >
              <b-badge v-if="data.item.status == 2" variant="primary"
                >UNDERVIEW</b-badge
              >
              <b-badge v-if="data.item.status == 3" variant="danger"
                >UNCOMPLETED</b-badge
              >
              <b-badge v-if="data.item.status == 4" variant="success"
                >COMPLETED</b-badge
              >
            </div>
          </template>

          <template #cell(comments)="data">
            <b-container class="mx-4" fluid>
              <b-row cols="4">
                <b-col cols="1">
                  <div style="position: absolute; right: 0px">
                    <p
                      :id="`comments-target-${data.index}`"
                      v-if="data.item.comments != null"
                    >
                      {{
                        data.item.comments.length > 10
                          ? data.item.comments.substr(0, 10) + "..."
                          : data.item.comments
                      }}
                    </p>
                  </div>
                  <b-tooltip
                    v-if="data.item.comments"
                    :target="`comments-target-${data.index}`"
                    triggers="hover"
                    placement="left"
                    ><div>
                      <span>
                        {{ data.item.comments }}
                      </span>
                    </div></b-tooltip
                  >
                </b-col>
                <!-- <tabler-icon
                    icon="EyeIcon"
                    size="15"
                    title="View"
                    class="cursor-pointer text-primary"
                    @click="modalAllTraking(data.item.id,data.item.client_name)"
                  /> -->
                <b-col cols="1" class="ml-1">
                  <tabler-icon
                    icon="EyeIcon"
                    size="15"
                    title="View"
                    class="cursor-pointer text-primary"
                    @click="openModalViewTracking(data.item)"
                  />
                </b-col>
                <b-col cols="1">
                  <b v-if="data.item.status != 4" class="mx-1">|</b>
                </b-col>
                <b-col cols="1">
                  <tabler-icon
                    icon="AlignLeftIcon"
                    size="15"
                    title="Add"
                    class="cursor-pointer text-primary mr-3"
                    v-if="data.item.status != 4"
                    @click="openModalAddtracking(data.item)"
                  />
                </b-col>
                <!-- <tabler-icon
                    icon="AlignLeftIcon"
                    size="15"
                    title="Add"
                    class="cursor-pointer text-primary"
                    v-if="data.item.status != 4"
                    @click="modalAddTraking(data.item.id,data.item.client_name,data.item.program,data.item.account)"
                  /> -->
              </b-row>
            </b-container>
          </template>

          <template #cell(date_t)="data">
            <div>
              {{ data.item.date_t | myGlobalWithHour }}
            </div>
          </template>

          <template #cell(date_cool)="data">
            <div>
              {{ data.item.date_cool | myGlobalWithHour }}
            </div>
          </template>

          <template #cell(call_data)="data">
            <div class="text-center">
              <b-badge
                v-if="currentUser.role_id == 4 && data.item.status == 1"
                :class="`mr-1 ' ${
                  data.item.counter <= 0 ? null : 'cursor-pointer'
                }`"
                variant="danger"
                @click="
                  data.item.counter <= 0
                    ? null
                    : openModalCallCounter(data.item, 0)
                "
              >
                <feather-icon icon="MinusIcon" />
              </b-badge>
              <b-badge
                :id="`counter-target-${data.index}`"
                class="cursor-pointer"
                variant="primary"
                style="width: 40px"
              >
                {{ data.item.counter }}
              </b-badge>
              <b-tooltip
                v-if="data.item.counter >= 1"
                :target="`counter-target-${data.index}`"
                triggers="hover"
                placement="left"
                ><div>
                  <span
                    v-for="(item, i) in JSON.parse(data.item.call_data)"
                    :key="i"
                  >
                    {{ item.dates_calls | myGlobalWithHour }} <br />
                  </span></div
              ></b-tooltip>
              <b-badge
                v-if="currentUser.role_id == 4 && data.item.status == 1"
                class="ml-1 cursor-pointer"
                variant="success"
                @click="openModalCallCounter(data.item, 1)"
              >
                <feather-icon icon="PlusIcon" />
              </b-badge>
            </div>
          </template>

          <template #cell(updated_at)="data">
            <div>
              {{ data.item.updated_at | myGlobalWithHour }}
            </div>
          </template>

          <template #cell(action)="data">
            <b-dropdown
              boundary="viewport"
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  style="position: absolute; top: 0px"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                v-if="currentUser.role_id != 4"
                @click="openModalCompletedRounds(data.item, 4)"
                variant="success"
              >
                <feather-icon icon="PhoneForwardedIcon" />
                <span class="align-middle ml-50">Completed</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="
                  currentUser.role_id != 4 &&
                  data.item.status != 3 &&
                  statusAccount == 1
                "
                @click="openModalCompletedRounds(data.item, 3)"
                variant="warning"
              >
                <feather-icon icon="PhoneForwardedIcon" />
                <span class="align-middle ml-50">Uncompleted</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="currentUser.role_id == 4 && data.item.status == 1"
                @click="openModalAddtracking(data.item, 2)"
                variant="primary"
              >
                <feather-icon icon="PhoneForwardedIcon" />
                <span class="align-middle ml-50">In Revision</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="currentUser.role_id == 4 && data.item.status == 1"
                @click="openModalSendToStandBy(data.item)"
                variant="warning"
              >
                <feather-icon icon="PhoneForwardedIcon" />
                <span class="align-middle ml-50">Stand By</span>
              </b-dropdown-item>

              <b-dropdown-item
                @click="
                  openModalSendSMS(data.item.account_id, data.item.client_name)
                "
                variant="primary"
              >
                <feather-icon icon="MessageCircleIcon" />
                <span class="align-middle ml-50">Send SMS</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </template>
    </filter-slot>

    <modal-send-to-stand-by
      v-if="modalSendToStandBy"
      :callRoundUser="callRoundUser"
      @closeModal="closeModal"
    />

    <modal-task-call-rounds
      v-if="modalTaskCallRounds"
      :callRoundUser="callRoundUser"
      @closeModal="closeModal"
    />

    <modal-add-tracking
      v-if="modalAddTracking"
      :callRoundUser="callRoundUser"
      :status="trackingStatus"
      @closeModal="closeModal"
      @reload="$refs['refCallRounds'].refresh()"
    />

    <modal-view-tracking
      v-if="modalViewTracking"
      :callRoundUser="callRoundUser"
      @closeModal="closeModal"
    />

    <modal-completed-rounds
      v-if="modalCompletedRounds"
      :callRoundUser="callRoundUser"
      :status="completedRoundsStatus"
      @closeModal="closeModal"
      @reload="$refs['refCallRounds'].refresh()"
    />

    <modal-send-sms
      v-if="modalSendSMS"
      :nameLeads="clientsSMS[0]"
      @closeModal="closeModal"
    />

    <modal-call-counter
      v-if="modalCallCounter"
      :callRoundUser="callRoundUser"
      :amount="amount"
      @closeModal="closeModal"
      @reload="$refs['refCallRounds'].refresh()"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// Import Data
import fields from "../data/fields.list.data";
import filters from "../data/filter.list.data";

// services
import CallRoundsService from "../../service/call-rounds.service";

// modals
import ModalSendToStandBy from "@/views/commons/call-rounds/components/modals/SendToStandBy.vue";
import ModalTaskCallRounds from "@/views/commons/call-rounds/components/modals/TaskCallRounds.vue";
import ModalAddTracking from "@/views/commons/call-rounds/components/modals/AddTracking.vue";
import ModalViewTracking from "@/views/commons/call-rounds/components/modals/ViewTracking.vue";
import ModalCompletedRounds from "@/views/commons/call-rounds/components/modals/CompletedRounds.vue";
import ModalCallCounter from "@/views/commons/call-rounds/components/modals/CallCounter.vue";
import ModalSendSms from "@/views/commons/components/clients/modals/ModalSendSms.vue";

export default {
  components: {
    ModalSendToStandBy,
    ModalAddTracking,
    ModalTaskCallRounds,
    ModalViewTracking,
    ModalCompletedRounds,
    ModalCallCounter,
    ModalSendSms,
  },
  props: {
    tr: {
      type: Number,
    },
    statusAccount: {
      type: Number,
      required: true,
    },
    idStandBy: {
      type: Number,
      required: true,
    },
    status: {
      type: Number,
      required: true,
    },
    // onlyRead: {
    //   type: Boolean,
    //   required: true,
    // },
    // lead: {
    //   type: Object,
    //   required: true,
    // },
  },
  data: function () {
    return {
      items: "",
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Client...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      nextPage: 0,
      endPage: 0,
      noteStatus: 0,
      order: "asc",
      startPage: null,
      toPage: null,

      searchInput: "",
      isBusy: false,
      sortBy: "created_at",
      sortDesc: true,
      filter:
        this.status == 5
          ? filters.filter((col) => col.label != "Operator")
          : this.status == 4
          ? filters
          : filters,
      totalRows: 0,
      arrayColumns:
        this.tr == 1
          ? fields.filter(
              (col) => col.key != "operator_name" && col.key != "updated_at"
            )
          : fields.filter(
              (col) =>
                col.key != "call_data" &&
                col.key != "action" &&
                col.key != "date_cool"
            ),
      //modals
      modalTaskCallRounds: false,
      callRoundUser: {},
      clientsSMS: [],
      modalAddTracking: false,
      modalViewTracking: false,
      modalCompletedRounds: false,
      modalSendSMS: false,
      modalSendToStandBy: false,
      modalCallCounter: false,
      amount: null,
      trackingStatus: null,
      completedRoundsStatus: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    callDate() {
      return this.callRoundUser;
    },
    // Change Columns
    visibleFields: function () {
      if (this.isCeo || this.isSupervisor || this.isCoordinator) {
        return this.arrayColumns.filter((column) => column.visible);
      } else {
        let newArrayColumn = [...this.arrayColumns];
        // newArrayColumn.splice(3, 1);
        return newArrayColumn.filter((column) => column.visible);
      }
    },
    nameModule() {
      const name = this.moduleId == 7 ? "boostcredit" : null;
      return name;
    },
  },
  async created() {
    if (this.filter[3].options[0].text != "All") {
      this.filter[3].options.unshift({
        text: "All",
        value: this.currentUser.role_id == 3 ? 0 : 5,
      });
    }
    this.getAdvisorAndOperator();
    this.filter[3].model = this.status;
  },
  mounted() {
    //this.filter[3].model = this.currentUser.role_id == 3 ? 0 : 5;
    this.filter[3].model = this.status;
  },
  methods: {
    async getAdvisorAndOperator() {
      try {
        const paramsCompleted = {
          roles: "[4]",
          type: "1",
        };
        const paramsInProcess = {
          roles: "[3,2]",
          type: "0",
        };
        const dataAdvisor = await CallRoundsService.getAdvisors(
          paramsInProcess,
          this.moduleId
        );
        const dataOperator = await CallRoundsService.getAdvisors(
          paramsCompleted,
          this.moduleId
        );
        this.filter[4].options = dataAdvisor.data;
        this.filter[5].options = dataOperator.data;
      } catch (e) {
        console.log(e);
      }
    },
    async myProvider(ctx) {
      try {
        const params = {
          name_text: this.filterPrincipal.model,
          status:
            this.filter[3].model == null ? this.status : this.filter[3].model,
          // this.status == 4
          //   ? this.status
          //   : this.status == 5
          //   ? this.status
          //   : this.filter[3].model,
          advisor: this.filter[4].model /*this.advisor*/,
          program: this.$route.meta.programId,
          operator:
            this.status == 4 ? this.filter[5].model : "" /*this.operator*/, //null,
          tr: this.tr,
          user_id:
            this.currentUser.role_id != 3
              ? 0
              : this.currentUser
                  .user_id /*this.global.layout.role_id == 3 ? this.global.layout.id :*/,
          date_from: this.filter[0].model,
          date_to: this.filter[1].model,
          order: this.status == 4 ? 200 : 100, //ctx.sortDesc ? 2 : 100, //? 2 : 100, //== 1 ? 1 : 2,
          task: this.filter[2].model /*this.task*/,
          id_status_account: this.statusAccount,
          id_stand_by: this.idStandBy,
          page: ctx.currentPage,
          perPage: this.paginate.perPage,
        };
        const data = await CallRoundsService.searchCallRounds(params);
        this.items = data.data.data;
        this.startPage = data.data.from;
        this.paginate.currentPage = data.data.current_page;
        this.paginate.perPage = data.data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.data.last_page;
        this.totalRows = data.data.total;
        this.toPage = data.data.to;
      } catch (e) {
        console.log(e);
      }

      return this.items || [];
    },
    changeStatusOrder() {
      this.order = this.order === "asc" ? "desc" : "asc";
    },
    onChangeCurrentPage(e) {
      this.paginate.currentPage = e;
    },
    // butttons
    openModalTaskCallRounds(item) {
      this.callRoundUser = item;
      this.modalTaskCallRounds = true;
    },
    openModalAddtracking(item, status) {
      this.trackingStatus = status;
      this.callRoundUser = item;
      this.modalAddTracking = true;
    },
    openModalViewTracking(item) {
      this.callRoundUser = item;
      this.modalViewTracking = true;
    },
    openModalCompletedRounds(item, status) {
      this.callRoundUser = item;
      this.completedRoundsStatus = status;
      this.modalCompletedRounds = true;
    },
    openModalSendSMS(id, name) {
      this.clientsSMS = [{ id: id, name: name }];
      this.modalSendSMS = true;
    },
    openModalSendToStandBy(item) {
      this.callRoundUser = item;
      this.modalSendToStandBy = true;
    },
    openModalCallCounter(item, amount) {
      this.callRoundUser = item;
      this.amount = amount;
      this.modalCallCounter = true;
    },
    closeModal() {
      this.modalAddTracking = false;
      this.modalTaskCallRounds = false;
      this.modalViewTracking = false;
      this.modalCompletedRounds = false;
      this.modalSendSMS = false;
      this.modalSendToStandBy = false;
      this.modalCallCounter = false;
    },
    // async myProvider(ctx) {
    //   try {
    //     let params = {
    //             name_text: this.filterPrincipal.model,
    //             status: this.status,
    //             advisor: null /*this.advisor*/,
    //             program: this.$route.meta.programId,
    //             operator: /*this.operator*/ null,
    //             tr:  1,
    //             user_id: /*this.global.layout.role_id == 3 ? this.global.layout.id :*/ 0,
    //             date_from: this.filter[0].model,
    //             date_to: this.filter[1].model,
    //             order: ctx.sortDesc == 1 ? "desc" : "asc",
    //             task: null /*this.task*/,
    //             id_status_account: this.statusAccount,
    //             id_stand_by: this.idStandBy,
    //     };
    //     const data = await CallRoundsService.searchCallRounds(params);

    //     const items = data.data;
    //     this.startPage = data.from;
    //     this.currentPage = data.current_page;
    //     this.perPage = data.per_page;
    //     this.nextPage = this.startPage + 1;
    //     this.endPage = data.last_page;
    //     this.totalRows = data.total;
    //     this.toPage = data.to;

    //     if (this.isCeo || this.isSupervisor || this.isCoordinator) {
    //       return items || [];
    //     }

    //     return items || [];
    //   } catch (error) {
    //     console.error(error);
    //     return [];
    //   }
    // },
  },
};
</script>
