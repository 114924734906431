<template>
  <div>
    <b-modal
      v-model="modalViewTracking"
      modal
      title="TRACKING"
      size="xmd"
      modal-class="modal-primary"
      scrollable
      title-tag="h3"
      :no-close-on-backdrop="true"
      hide-footer
      @hidden="closeModal"
    >
      <b-row cols="1">
        <b-col>
          <b-form-group class="mt-2">
            <b-input-group prepend="Client">
              <div
                class="form-control h-auto bg-transparent border-secondary sms-container"
              >
                {{ callRoundUser.client_name }}
              </div>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        ref="refTrackingCallRounds"
        small
        table-class="text-nowrap"
        :items="myProvider"
        :fields="fields"
        :current-page="paginate.currentPage"
        :per-page="paginate.perPage"
        :busy="isBusy"
        sticky-header="60vh"
        show-empty
        responsive="sm"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>

        <template #cell(created_at)="data">
          <span>{{ data.item.created_at | myGlobalWithHour }}</span>
        </template>

        <template #cell(type)="data">
          <span class="text-primary">{{
            data.item.type == 1
              ? "Send Task"
              : data.item.type == 2
              ? "Revision"
              : data.item.type == 3
              ? "Uncompleted"
              : data.item.type == 4
              ? "Completed"
              : data.item.type == 5
              ? null
              : data.item.type == 6
              ? "Call"
              : null
          }}</span>
        </template>

        <template #cell(comments)="data">
          <span v-html="data.item.comments" />
          <br />
          <b-button
            v-if="data.item.route"
            size="sm"
            variant="success"
            @click="downloadFile(data.item.route)"
          >
            <span class="mr-1">DOWNLOAD FILE</span>
            <feather-icon icon="DownloadIcon" />
          </b-button>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import CallRoundsService from "@/views/commons/call-rounds/service/call-rounds.service";
import fields from "@/views/commons/call-rounds/components/modals/fields.view-tracking.data";

export default {
  props: {
    callRoundUser: {
      type: Object,
    },
  },
  data() {
    return {
      // modalViewTrackingValue: this.callRoundUser.id,
      isBusy: false,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      fields,
      items: "",
      modalViewTracking: false,
    };
  },
  created() {
    this.modalViewTracking = true;
  },
  methods: {
    async myProvider(ctx) {
      try {
        const params = {
          id: this.callRoundUser.id,
          page: ctx.currentPage,
          perPage: this.paginate.perPage,
        };
        const data = await CallRoundsService.alltrakingcallround(params);
        this.items = data.data;
      } catch (e) {
        console.log(e);
      }

      return this.items || [];
    },
    closeModal() {
      this.$emit("closeModal");
    },
    async dowloadUsing(IMG_URL, FILE_NAME) {
      const image = IMG_URL;
      const imageBlog = new Blob([image], {
        type: "application/jpeg",
      });
      const imageURL = URL.createObjectURL(imageBlog);

      const anchor = document.createElement("a");
      anchor.href = imageURL;
      anchor.download = FILE_NAME;

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      URL.revokeObjectURL(imageURL);
    },

    async downloadFile(routeName) {
      const params = {
        route: routeName,
      };
      if (routeName) {
        const response = await CallRoundsService.dowloadfilecallround(params);
        const myArray = routeName.split("/", 5);
        await this.dowloadUsing(response.data, myArray[4]);

        return response;
      }
      this.showInfoSwal("Warning", "No file to download");
    },
  },
};
</script>

<style></style>
